
/*GALLERY PAGE*/
.gallery {
    width: 100%;
    padding: 150px 0;
}

/*LEFT FILTER MENU*/
.filter-viewer {
    width: 300px;
    position: fixed;
    top:150px;
    left: 4%;
}
    .filter-viewer h1 {
        font-family: 'Articulat.CF-V3-Heavy', sans-serif;
        font-size: 65px;
    }
    .filter-viewer h1 span{
        color: #3e8af6;
    }
    .mobile-title{display: none;}

    .filter-box {
        width: 315px;
        margin-top: 30px;
        overflow: auto;
        height: 40vh;
        padding-right:5px;
        cursor: pointer;
    }
    .filter-box::-webkit-scrollbar {
    /*  display: none;*/
        width: 14px;
    }
    .season-filter{
        padding-top: 30px;
    }
    .filter-item {
        border-bottom: 1px solid #f0f1f3;
        height: 35px;
        width: 100%;
        text-align: start;
        vertical-align: bottom;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 1rem;
    }
    .filter-item.expanded:hover{background: rgba(255,255,255,0.08);}
    .filter-item:first-of-type{padding-top: 5px;}
    .filter-item label{width: 100%;cursor: pointer;padding: 10px 0;}
    .filter-item input{float: right;cursor: pointer;margin-right: 15px;}

    .expanded {
        border-bottom: none;
        font-size: 14px;
    }


/*RIGHT SECTION, DISPLAYS LIST OF NFTS*/ 
.image-viewer {
    width: calc(90% - 300px);
    margin-right: 3%;
    float: right;
    display: block;
}
    .loadingView {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        text-align: center;
        height: 80vh;
    }
    .loadingView.g{
        width: calc(90% - 300px);
        display: flex;
        height: 50vh;
        float: right;
    }

    .image-holder {
        float:left;
        width: 18%;
        width: 15%;
        margin: 10px 1%;
        opacity: 1;
        transition: opacity 200ms;
        transition-timing-function: ease-out;
        z-index: 1;
    }
    .border {
        border: 1px solid red;
    }
    .image-holder:nth-child(6n+1){margin-left: 0;}
    .image-holder:nth-child(6n){margin-right: 0;}


@media(max-width:1500px){
    .image-holder {width: 23%;}
        .image-holder:nth-child(6n+1){margin-left: 1%;}
    .image-holder:nth-child(6n){margin-right: 1%;}
}

    .image-holder:hover {
        opacity: 0.75;
        transition: opacity 200ms;
        transition-timing-function: ease-in;
        cursor: pointer;
    }
        img.image-item {
            width: 100%;
            border-radius: 3px;
        }

    .paginate-container{
        padding-top: 30px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .paginate {
        padding: 0 1%;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;

    }
    .prev,.next{
        font-family: monospace;
        border:none;
        line-height: 30px;
        background-color: #3e8af6;
/*        background: none;*/
        padding: 0 8px 4px;
        font-size: 30px;
        color:#fff;
        transition: .1s opacity;
        cursor: pointer;
        margin:0 1%;
        display: block;
    }
    .prev:hover,.next:hover{opacity: .7;}
    .prev[disabled],.next[disabled]{color:#5a5a5a;background: #444;}

    .image-text {
        text-align: center;
        min-height: 40px;
    }

    


/*FILTER MENU BUTTON HIDDEN WHEN NOT MOBILE*/
#filter-menu__btn{
  display: none;
}
#filter-menu__toggle{
    opacity: 0;
    position: absolute;
}
@media only screen and (max-width: 1080px) {

  .loadingView.g{width: 100%;}

  /*FILTER MENU BUTTON SHOWING ON MOBILE*/
  #filter-menu__btn {
    display: block;
    opacity: 0;
  }

  .filter-menu__btn {
    position: fixed;
    top: 170px;
    left: 30px;
    width: 30px;
    height: 30px;
    cursor: pointer;
    z-index: 1;
  }
  .filter-menu__btn > span,
  .filter-menu__btn > span::before,
  .filter-menu__btn > span::after {
    display: block;
    position: absolute;
    width: 30px;
    height: 3px;
    background-color: #f0f1f3;
    transition-duration: .25s;
  }
 .filter-menu__btn > span::before {
    content: '';
    top: -8px;
    width: 30px;
    left:-5px;
  }
  .filter-menu__btn > span{
    width: 20px;
  }
  .filter-menu__btn > span::after {
    content: '';
    top: 8px;
    width: 10px;
    left: 5px;
  }

  /*GALLERY MOBILE CLICKED/SHOWING*/
  #filter-menu__toggle:checked + .filter-menu__btn {
    z-index: 12;
    right:5%;
    left: initial;
    top:10%;
  }
  #filter-menu__toggle:checked + .filter-menu__btn > span {
    transform: rotate(45deg);
  }
  #filter-menu__toggle:checked + .filter-menu__btn > span::before {
    top: 0;
    transform: rotate(0deg);
  }
  #filter-menu__toggle:checked + .filter-menu__btn > span::after {
    top: 0;
    left: -5px;

    transform: rotate(90deg);
    width: 30px;

  }

  .filter-viewer-container{
    display: block;
    position: fixed;
    left: -200%;
    margin: 0;
    opacity: 0;
    z-index: -1;
  }
  #filter-menu__toggle:checked ~ .filter-viewer-container {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 11;
    background-color: #1d2122;
    opacity: 1;
    transition: opacity 250ms;
    z-index: 10;
  }

  #filter-menu__toggle:checked ~ .filter-viewer-container .filter-viewer{
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 82%;
    padding: 9%;
  }
  #filter-menu__toggle:checked ~ .filter-viewer-container .filter-viewer .filter-box{
    width: initial;
    height: 45vh;
  }
    .mobile-title{
        display: block;
        width: 70%;
        margin: 0 15% 20px;
    }
    .mobile-title h1{
        font-size: 65px;
    }

    .image-viewer{
        width: 70%;
        margin: 0 15% 0;
        display: block;
        padding-bottom: 100px;
    }
    .image-holder{
        width: 23%;
        margin:10px 1% 20px;
        float: left;
    }
    .image-holder img{width: 100%} 
  }
@media (max-width: 725px) {
    .mobile-title{
        width: 80%;
        margin: 0 4% 20px 16%;
    }
    .mobile-title h1,
    .filter-viewer h1{
        font-size: 36px;
    }
    .image-viewer{
        width: 80%;
        margin: 0 5% 0 15%;
    }
    .image-holder{
        width: 47%;
        margin:10px 1.5%;
    }
    #filter-menu__toggle:checked ~ .filter-viewer-container .filter-viewer .filter-box{
        height: 60vh;
    }
}
@media (max-width: 500px) {
    #filter-menu__toggle:checked + .filter-menu__btn{
        top: 9%;
    }
    .mobile-title{
        width: 78%;
        margin: 0 4% 20px 18%;
    }
    .image-viewer{
        width: 78%;
        margin: 0 5% 0 17%;
    }
}
@media (max-width: 375px) {
    #filter-menu__toggle:checked + .filter-menu__btn{
        top: 8%;
    }
    .mobile-title{
        width: 75%;
        margin: 0 4% 20px 21%;
    }
    .image-viewer{
        width: 75%;
        margin: 0 5% 0 20%;
    }
}

/******MODAL******/
.modal {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 60%;
    height: 75%;
    background-color: rgba(30, 33, 33,0.95);
    z-index: 2;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;

    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    padding: 30px;
}
span.close-modal,
span.close-modal::after {
    display: block;
    position: absolute;
    top:40px;
    right: 3%;
    width: 20px;
    height: 3px;
    background-color: #f0f1f3;
    transition-duration: .25s;
    transform: rotate(45deg);
  }
span.close-modal::after {
    content: '';
    top: 0px;
    transform: rotate(90deg);
}

    .modal-left {
        width: 50%;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .modal-right {
        height: auto;
        width: 40%;
        min-width: 430px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }


.nft-os-link{
    border: 1px solid #f0f1f3;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
    position: absolute;
    top: 95px;
    right: 3%;
}
.nft-os-link img{
      width:20px;
      height: auto;
}
    .nft-os-link:hover img{
        opacity: .5;
        transition: opacity 500ms;
        transition-timing-function: ease-in;
    }
    .modal-right > p {
        text-align: left;
        width: 80%;
        margin-bottom: -10px;
    }

    .modal-right > h1 {
        text-align: left;
        width: 80%;
        margin-bottom: 20px;
        font-family: 'Articulat.CF-V3-Heavy';
        font-size: 50px;
    }

    .modal-right-header {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        align-items: center;
        width: 79%;
    }

    .modal-right-header > p {
        text-align: left;
        width: 80%;
        margin-bottom: -10px;
    }

    .modal-header { 
        flex-basis: auto;
        cursor: pointer;
        opacity: 1;
        transition: opacity 200ms;
        transition-timing-function: ease-out;
    }

    .modal-header:hover {
        opacity: 0.5;
        transition: opacity 200ms;
        transition-timing-function: ease-in;
    }


    .gallery-page .attribute {
        width: 80%;
        display: flex;
        text-align: left;
        flex-wrap: wrap;
        text-transform: capitalize;
    }

    .gallery-page .attribute-left {
        flex-basis: 40%;
        padding: 0.5%;
    }

    .gallery-page .attribute-right {
        flex-basis: 55%;
        background-color: rgba(255, 255, 255, 0.2);
        padding: 5px 2%;
        border-radius: 5px;
    }

    .modal-image-item {
        height: 25vw;
        border-radius: 5px;
    }

    .modal-bg {
        background-color: rgba(0,0,0,0.4);
        position:fixed;
        top:0px;
        right:0px;
        bottom:0px;
        left:0px;
        z-index: 2;
    }

@media only screen and (max-width: 1500px) {
    .modal {
        width: 70%;
    }
    .modal-image-item{
        height: initial!important;
        width: 90%;
    }
}
@media only screen and (max-width: 1300px) {
    .modal {width: 80%;}
    .modal-left {
        width: 45%;
    }
    .modal-right {
        width: 45%;
        min-width: 450px;
    }
}
@media only screen and (max-width: 1080px) {
    .gallery-page .modal {

    }
    .gallery-page .modal-left {
        width: 40%;
    }
    .gallery-page .modal-right {
        width: 60%;
        min-width: 400px;
    }
    .gallery-page .modal-image-item{
        height: initial!important;
        width: 90%;
    }
    .gallery-page .modal h1{
        font-size: 30px;
    }
}
@media only screen and (max-width: 900px) {
    .gallery-page .attribute{
        font-size: 14px;
    }
    .gallery-page .modal-right {
        min-width: initial;
    }
}
@media only screen and (max-width: 767px) {
    .gallery-page .modal {
        width: 90%;
        height: calc(100% - 60px);
        padding: 30px 5%;
        display: block;
        overflow-y:scroll;
    }
    .gallery-page .modal-left {width: 80%;margin:40px auto;}
    .gallery-page .modal-left img {max-width: 300px}
    .gallery-page .modal-right { width: 100%;margin:0px auto;}
    .nft-os-link{
        border: 1px solid #f0f1f3;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        width: 40px;
        position: absolute;
        top: 95px;
        right: 1.5%;
    }
    .nft-os-link img{
          width:20px;
          height: auto;
    }
}