/* .mint-viewer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content: center;
    text-align: center;
    min-height: 99vh;
    overflow: auto;
    row-gap: 25px;
}

.image-mint {
    width: 300px;
    z-index: 0;
}

.mint-viewer > h1 {
    font-family: 'Articulat.CF-V3-Heavy';
    font-size: 70px;
    width: max(350px, 50%);
    text-align: center;
}

.mint-button {
    border: none;
    background-color: #f0f1f3;
    font-family: 'Articulat.CF-V3-Heavy';
    font-size: 21px;
    border-radius: 2px;
    padding: 6px;
    margin: 10px;
    text-align: center;
    cursor: pointer;
    z-index: 1;
}

.error {
    color: rgb(235, 83, 83)
}

.statusText {
    text-align: center;
    width: 350px;
    font-size: 20px;
    height: 100px;
}

.status {
    z-index: 2;
}
@media only screen and (max-width: 1080px) {
    .mint-viewer > h1 {
        font-family: 'Articulat.CF-V3-Heavy';
        font-size: 55px;
        width: max(350px, 50%);
        text-align: center;
    }
  }

.mint-box {
    border: 2px solid #f0f1f3;
    width: 350px;
    height: 200px;
    padding: 30px;

    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    background-color: rgba(255, 255, 255, 0.1);
}

.mint-select {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 20px;
}

.mint-amount {
    border: 1px solid #f2f2f2;
    width: 50%;
}

.number {
    font-size: 35px;
    width: 50px;
}

.big {
    font-size: 24px;
}

.icon-img {
    width: 60px;
    height: 60px;
}

.tall {
    height: 70px;
}

.icon-holder {
    display: flex;
    column-gap: 25px;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
} */


  h1, h2, h3, h4{
    font-family: 'Articulat.CF-V3-Heavy', sans-serif;
        font-size: 2em;
  }


  .clearfix:after {
    clear: both;
    content: "";
    display: table;
}

.karu{
	font-family: 'Quicksand', sans-serif;
	padding-top:100px;
	text-align: center;
}

.karu .chibae{
	padding-bottom: 100px;
	width: 700px;
	max-width: 90%;
	margin: 0 auto;
	position: relative;
}
.karu .chibae:after{
	content: '';
	height: 570px;
	width: 570px;
	position: absolute;
	background: url(../image/chibae.png) no-repeat center center;
	background-size: contain;
	bottom: 0;
	right: -400px;
}

.presents{
	font-family: 'Articulat.CF-V3-Heavy', sans-serif;
	font-size: 32px;
	font-weight: 500;
	padding: 80px 0 30px;
}
.presents .blue{
	color:#3E8AF6;
}

.karu-mint-box{
	font-family: 'Quicksand', sans-serif;
	height: 700px;
/*	width: 700px;*/
/*	max-width: 90%;*/
	margin:0 auto;
	border-radius: 50px;
	border:20px solid #fff;
	background: url(../image/chibae-bg.png) no-repeat center center;
	background-size: cover;
	
}

	.karu-mint-box h1,
	.karu-mint-box ul li span.big-number,
	.karu-mint-box ul li span.small-text,
	.karu-mint-box span.choose{
		font-size: 100px;
		color: #2A2728;
		text-shadow: 
			-2px -2px 0 #fff,
			-1px -2px 0 #fff,
			-2px -1px 0 #fff,
		     0   -2px 0 #fff,
		     2px -2px 0 #fff,
		     1px -2px 0 #fff,
		     2px -1px 0 #fff,
		     2px  0   0 #fff,
		     2px  2px 0 #fff,
		     1px  2px 0 #fff,
		     2px  1px 0 #fff,
		     0    2px 0 #fff,
		    -2px  2px 0 #fff,
		    -1px  2px 0 #fff,
		    -2px  1px 0 #fff,
		    -2px  0   0 #fff;
	}


	.karu-mint-box h1{
		font-family: 'Quicksand', sans-serif;
		font-size: 65px;
		font-weight: 700;
	    padding: 60px 30px 50px;
	}
	.karu h1 br{display: none;}

	.karu-mint-box ul{
		list-style-type: none;
		display: flex;
		flex-direction: row;
		column-gap: 30px;
	    justify-content: center;
	}
		.karu-mint-box ul li {
			display: flex;
			flex-direction: column;
		}
			.karu-mint-box ul li span.big-number {
				font-size: 100px;
				font-weight: 700;
			}
			.karu-mint-box ul li span.small-text {
				font-size: 24px;
				position: relative;
			    top: -15px;
			}
			.karu-mint-box button{
			    padding: 15px 80px 20px 20px;
			    font-size: 36px;
			    border-radius: 50px;
			    border: 6px solid #fff;
			    font-family: 'Quicksand', sans-serif;
			    color: #fff;
			    font-weight: 600;
			    line-height: 36px;
			    background: #3E8AF6;
			    cursor: pointer;
			    position: relative;
				margin-top: 60px;
			}

			.karu-mint-box button:after{
				content: '';
				display: block;
				position: absolute;
				height: 36px;
				width: 25px;
				right:20px;
			    top: 50%;
			    transform: translateY(-50%);
				background: url(../image/eth.png) no-repeat center center;
				background-size: contain;
			}
			.karu-mint-box button:hover{
			    background: #5da0ff;
			}
			.karu-mint-box button:disabled{
				background: #D6DCE3;
				cursor: not-allowed;
			}


/***********State = Mint***********/

	.karu-mint-box span.choose{
		font-family: 'Quicksand', sans-serif;
		font-size: 24px;
		font-weight: 600;
	}
	.karu-mint-box .mint input[type="number"]{
		font-family: 'Quicksand', sans-serif;
		font-size: 42px;
		font-weight: 600;
		color: #2A2728;
		width: 125px;
		border-radius: 10px;
		border: 6px solid #3E8AF6;
	    text-align: center;
	    margin-top: 60px;
		-webkit-appearance: none;

	}
	.karu-mint-box .mint input::placeholder{
		color:#D6DCE3;
	}
	.karu-mint-box button.mint{
		padding: 15px 50px 20px 50px;

	}
	.karu-mint-box button.mint:after{
		display: none;
	}
	.view-chibaes{
        padding: 12px 25px 15px 25px;
	    font-size: 24px;
	    border-radius: 50px;
	    border: 6px solid #fff;
	    font-family: 'Quicksand', sans-serif;
	    color: #fff;
	    font-weight: 600;
	    line-height: 24px;
	    background: #3E8AF6;
	    cursor: pointer;
	    position: relative;
		margin-top: 40px;
	 
		text-decoration: none;
	}

/************State = Post Mint**************/
.karu .post-mint{
    display: flex;
    align-items: center;
    flex-direction: column;
    row-gap: 60px;
}
.karu .check.icon {
	color: #7db27d;
    margin-left: 3px;
    margin-top: 4px;
    width: 80px;
    height: 40px;
    border-bottom: solid 13px #7db27d;
    border-left: solid 13px #7db27d;
    transform: rotate(-45deg);
}

.karu .fail.icon {
  color: #cd6161;
  position: relative;
  margin-top: 0;
  margin-left: 0;
  width: 100px;
  height: 100px;
}

.karu .fail.icon:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100px;
  height: 13px;
  background-color: #cd6161;
  transform: translate(-50%, -50%) rotate(-45deg);
}

.karu .fail.icon:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100px;
  height: 13px;
  background-color: #cd6161;
  transform: translate(-50%, -50%) rotate(45deg);
}

/******media queries*******/
@media(max-width: 1500px){
	.karu .chibae:after{
		height: 400px;
		width: 400px;
		right: -230px;
	}
}
@media(max-width: 1200px){
	.karu-mint-box h1{
		font-size: 48px;
	}
	.karu-mint-box ul li span.big-number {
		font-size: 64px;
	}
	.karu-mint-box ul li span.small-text{
		font-size: 18px;
	}
	.karu-mint-box button{
		font-size: 28px;
	}
	.karu-mint-box button:after{
		width: 24px;
    	right: 20px;
	}
	.karu-mint-box{
	    height: auto;
	    padding-bottom: 160px;
	}
	.karu .chibae:after{
		right: -140px;
	}
}
@media(max-width: 980px){
	.karu .chibae:after{
		right: 0px;
		height: 300px;
	    width: 300px;
	}
	.karu .chibae{
		padding-bottom: 120px;
	}
}
@media(max-width: 550px){
	.karu h1 br{display: block;}
	.karu .chibae {
	    padding-bottom: 160px;
	}
	.karu .chibae:after {
	    height: 250px;
	    width: 250px;
	}
	.karu-mint-box{    
	    padding-bottom: 80px;
	}
    .karu-mint-box ul li span.big-number {
		font-size: 50px;
	}
    .karu-mint-box ul li span.small-text{
		font-size: 12px;
	}
}



  
  
  