* {
  margin: 0;
  padding: 0;
}
@font-face {
  font-family: 'Articulat.CF-V3';
  src: local('Articulat.CF-V3'), url('./fonts/ArticulatCF/ArticulatCF-Normal.otf') format('opentype');
}
@font-face {
  font-family: 'Articulat.CF-V3-Bold';
  src: local('Articulat.CF-V3-Bold'), url('./fonts/ArticulatCF/ArticulatCF-DemiBold.otf') format('opentype');
}
@font-face {
  font-family: 'Articulat.CF-V3-Heavy';
  src: local('Articulat.CF-V3-Bold'), url('./fonts/ArticulatCF/ArticulatCF-Heavy.otf') format('opentype');
}

html{
  min-height: 100%;
  scroll-behavior: smooth;
  width: 100%;
}
body {
  width: 100%;
  font-family: 'Articulat.CF-V3', Consolas, "Courier New", monospace;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  background-attachment: fixed;
  /* background-image: url('https://dx8cklxaufs1v.cloudfront.net/baecafeweb/image/BAECAFE-background.jpg'); */
  background-image: url('./image/bg-karu.jpg');
  color: #FFFFFF;
  background-color: rgba(110,181,228,255);

}
h1, h2, h3, h4{
  font-family: 'Articulat.CF-V3-Heavy', sans-serif;
      font-size: 2em;
}

@media only screen and (max-width: 1500px) {
  body {
    background-image: url('./image/mobile-karu-bg.jpg');
  }
}
