::-webkit-scrollbar {
    width: 18px;
    background-color: #bbbbbb;
}
::-webkit-scrollbar-track {
  background-color: transparent;
}
::-webkit-scrollbar-thumb {
  background-color: #818181;
   border-radius: 20px;
   border: 4px solid transparent;
  background-clip: content-box;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #444;
}

.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

.page {
  display: flex;
  flex-direction: column;
  z-index: 1;
  align-items: center;
}
a {
  text-decoration: none;
  color: #3e8af6;
}

.header {
  background: rgba(176,216,251,255);
  position: fixed;
  width: 87%;
  padding: 20px 3% 20px 10%;
	margin:0 auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 30px;
  font-size: 18px;
  z-index: 2;
  /*position: fixed;*/
}
    a.logo,
    .headerIcon {
      width: 60px;
      height: 60px;
      cursor: pointer;
    }
    .header-item {
      cursor: pointer;
      opacity: 1;
      transition: opacity 500ms;
      transition-timing-function: ease-out;
    }
    .header-item:hover {
      cursor: pointer;
      opacity: 0.5;
      transition: opacity 500ms;
      transition-timing-function: ease-in;
    }
  

  /*header social icons*/
  .header-right {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: auto;
    column-gap: 10px;
  }
    .header-right a{
      border: 1px solid #f0f1f3;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 50px;
      width: 50px;
    }
    .header-icon {
      width: 30px;
      height: 30px;
      height: auto;
      float: none;
      clear: both;
      border: 1px solid #f0f1f3;
      border-radius: 50%;
      padding: 8px;
      opacity: 1;
      transition: opacity 500ms;
      transition-timing-function: ease-out;
    }
    .header-icon:hover {
      opacity: .5;
      transition: opacity 500ms;
      transition-timing-function: ease-in;
    }
    img.header-icon{
      border:none;
      border-radius:0;
      width:30px;
      padding: 0;
    }

@media (max-width:1250px){
	.header{
        column-gap: 20px;
        font-size:18px;
	}
		.header-right>a{
	    height: 40px;
	    width: 40px;
	    margin:0px;
	}
		img.header-icon{
			width:24px;
		}
}



.button {
  border: none;
  border-radius: 5px;
  cursor: pointer;
  height: 18px;
  font-family: 'Articulat.CF-V3-Bold';

  color:#3e8af6;
  border: 1px solid #f0f1f3;
  padding: 10px;
  
  text-align: center;

  opacity: 1;
  transition: opacity 500ms;
  transition-timing-function: ease-out;
}
.button-connect {
  border-radius: 3px;
  border: none;
  padding: 7px;
  font-family: 'Articulat.CF-V3-Bold';
  cursor: pointer;
  margin-right: 10px;
  background-color: #3e8af6;
  color:#f0f1f3;
  border: 1px solid #f0f1f3;
  opacity: 1;
  transition: opacity 500ms;
  transition-timing-function: ease-out;
}
.button-connect:hover {
  opacity: .5;
  transition: opacity 500ms;
  transition-timing-function: ease-in;
}
.button:hover {
  opacity: .5;
  transition: opacity 500ms;
  transition-timing-function: ease-in;
}


.main {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.coming-soon {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-family: 'Articulat.CF-V3-Heavy';
  font-size: 100px;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.75);
  height: 93.5%;
  z-index: 5;
}



/*MENU + HAMBURGER*/
a.logo{
  position: fixed;
  top: 12px;
  left: 20px;
  z-index: 10;
}
#menu__btn{
  display: none;
}
#menu__toggle{
    opacity: 0;
    position: absolute;
  }

/*MOBILE MENU*/
@media(max-width:1080px){

  .header{
    width: 90%;
    padding: 40px 5% 30px;
  }
  .header p{
    text-align: center;
    padding: 30px 0;
  }
  .header button.button-connect{
    margin:20px auto;  
    display: block;
  }

  .header-right {
    margin: 0 auto;
    max-width: 400px;
    padding: 10px 10px;
  }



  #menu__btn {
    display: block;
    opacity: 0;
  }

  #menu__toggle:checked + .menu__btn > span {
    transform: rotate(45deg);
  }
  #menu__toggle:checked + .menu__btn > span::before {
    top: 0;
    transform: rotate(0deg);
  }
  #menu__toggle:checked + .menu__btn > span::after {
    top: 0;
    transform: rotate(90deg);
  }
  #menu__toggle:checked ~ .menu__box {
    top: 0 !important;
  }
  .menu__btn {
    position: fixed;
    top: 40px;
    right: 30px;
    width: 30px;
    height: 30px;
    cursor: pointer;
    z-index: 10;
  }
  .menu__btn > span,
  .menu__btn > span::before,
  .menu__btn > span::after {
    display: block;
    position: absolute;
    width: 100%;
    height: 3px;
    background-color: #f0f1f3;
    transition-duration: .25s;
  }
  .menu__btn > span::before {
    content: '';
    top: -10px;
  }
  .menu__btn > span::after {
    content: '';
    top: 10px;
  }

  .menu__box {
    display: block;
    position: fixed;
    top: -200%;
    margin: 0;
    transition-duration: .25s;
    z-index: 9;
  }
   .menu__box .header-item{
    text-align: center;
    padding: 20px;
    display: block;
   }

   body:before{
    content: '';
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    height: 95px;
    background-color: rgba(176,216,251,255);
    z-index: 8;
   }
  
}