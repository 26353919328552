.home {
/* border: 10px solid transparent;*/
 display: flex;
 justify-content: center;
 flex-wrap: wrap;
 flex-direction: row;
 scroll-behavior: smooth;
}
.accent {
    color: #3e8af6;
}


/*HOME BANNER*/
.home{
    overflow: hidden;
}
.left {
    width: 100%;
    min-width: 400px;
    padding: 150px 50px 50px;
    text-align: left;
    font-size: 80px;
    font-family: 'Articulat.CF-V3-Heavy';
    display: block;
    overflow: hidden;
}
    .banner-text{
        padding: 100px 2% 100px 6%;
        width: 50%;
        float: left;
    }
    .left .banner-text h1 {
        font-size: 130px;
        line-height: 1;
        padding: 0 0 20px 0;
    }
    .left .right{
        width: 36%;
        padding:70px 6% 0 0;
        float: left;
    }

    .videoWrapper {
      position: relative;
      padding-bottom: 56.25%; /* 16:9 */
      height: 0;
    }
    .videoWrapper iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: none;
    }

    .left .banner-text p,
    .left .banner-text a {
        font-family: 'Articulat.CF-V3-Bold';
        font-size: 18px;
        margin-left: 10px;
        color: #fff;
    }
    a.offset-scroll {
        padding-top: 200px;
        display: block;
    }
    .mobilevid{display: none;}

@media(max-width: 1749px){
    .left .banner-text h1 { font-size: 100px;}
}
@media(max-width: 1499px){
    .left .banner-text h1 { font-size: 90px;}
    .left .right{width: 44%; padding:70px 2% 0 0;}
    .banner-text{ width: 50%; padding: 100px 2% 100px 2%;}
}
@media(max-width: 1279px){
    .left .banner-text h1 { font-size: 72px;}
    .left .right{width: 44%; padding:70px 2% 0 0;}
    .banner-text{ width: 50%; padding: 100px 2% 100px 2%;}
}
@media(max-width: 1079px){
    .left {padding: 150px 20px 50px;}
    .left .banner-text h1 { font-size: 72px; text-align: center;}
    .left .right{display: none;}
    .banner-text{ width: 96%; padding: 60px 2% 0px 2%;}
    a.offset-scroll{text-align: center;padding-top: 60px;}
    .mobilevid{display: block;width: 100%; padding:70px 0 0 0;max-width: 500px;margin: 0 auto;}
}
@media(max-width: 579px){
    .left .banner-text h1 { font-size: 50px; }
    .left .banner-text p { font-size: 16px; }
}
@media(max-width: 479px){
    .left .banner-text h1 { font-size: 40px; }
    .left .banner-text p { font-size: 13px; }
    .mobilevid{max-width: 300px;}
}





/*ROADMAP*/

.roadmap {
    width: 100%;
    padding: 120px 0 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 50px;
}
    .roadmap-panel {
        width: 80%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        column-gap: 50px;
    }
    .roadmap-desc {
        width: max(350px, 40%);
        padding: 30px;
    }
    .roadmap-desc h1,
    .roadmap-desc h2,
     {
        font-family: 'Articulat.CF-V3-Heavy';
        font-size: max(20px, 3.75em);
        color: #3e8af6;
    }

    .roadmap-desc p {
        font-size: max(16px, 1.2em);
        line-height: max(1.5);
    }

    .roadmap-img {
        width: max(20vw, 150px);
        border-radius: 3px;
    }
@media(max-width: 1080px){
    .roadmap {    
        padding: 200px 0 200px;
    }
    .roadmap-panel {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        column-gap: 0px;
    }
    .roadmap-desc h1,
    .roadmap-desc h2
     {
        font-size: max(14px, 1.75em);
    }

    .roadmap-desc p {
        font-size: max(12px, 1em);
    }

    /*fix random scrollbar on mobile*/
    .roadmap,
    .roadmap-panel{
        overflow: hidden;
    }

}

@media(max-width: 800px) {
    .roadmap-img{
        padding: 0 30px;

    }
}
@media(max-width: 575px) {
    .roadmap-img{padding: 30px 10%;width: 80%;max-width: 350px;}
    .roadmap-panel{flex-direction: column;}
    .roadmap-panel:nth-of-type(odd){flex-direction: column-reverse;}
    .roadmap-desc{
        max-width: 80%;
        padding: 0px 10%;
    }
}


/*TEAM*/
.team {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
}

.team-holder {
    margin-top: 20px;
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    column-gap: 50px;
    margin-bottom: 200px;
    flex-wrap: wrap;
    row-gap: 30px;
}

.team-item {
    height: 400px;
    width: 280px;
    display: flex;
    flex-direction: column;
    align-items: left;
}

.twitter-icon {
    width: 37px;
}

.team-img {
    width: 100%;
    border-radius: 2px;
}

.team-img {
    width: 100%;
    border-radius: 2px;
}
.team-img:hover {
    width: 100%;
    border-radius: 2px;
    opacity: .8;
    transition: opacity .3s;
}
.team h2 {
    width: 100%;
    text-align: center;
    font-family: 'Articulat.CF-V3-Heavy';
    font-size: max(20px, 3.75em);
    padding: 0 0 50px;
}

.founder{text-align:center;margin-bottom: 200px;}
.founder img{border-radius:50%;width: 150px;}
.founder .twitter-icon{width: 20px;position: relative;bottom: -3px;}

@media only screen and (max-width: 1080px) {
    .right {
        width: 40vw;
        height: 100vh;
        margin-top: 0px;
        flex-basis: 100%;
        margin-top: 100px;
    }
    .home {
        height: 100%;
        width: 100%;
    }
    .offset {margin: 0px;}
    .left {
        width: 90vw;
        font-size: calc(min(2rem, 60px, 5em));
    }
    .left .banner-text h1,
    .left .banner-text h2,
     {
        font-size: 55px;
            font-size: 2em;
        text-align: center;
        line-height: normal;
    }
    .left .banner-text p {
        text-align: center;
        width: 100%;
        margin-left: 0;
    }
    .team {margin-bottom: 0;}

  }

