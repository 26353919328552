.stake {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    padding: 60px 0 0;

}

.stake-viewer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    column-gap: 10px;
    height: 90vh;
    z-index: 1;
    flex-basis: 63%;

    margin-top: 3vh;

    scroll-behavior: smooth;
}

.stake-box {
    flex-basis: 45%;
    height: 40vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    background-color: rgba(30,33,33,0.95);
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
}

.full {
    flex-basis: 90%;
    text-align: center;
}

.stake-top {
    width: 100%;
    height: 5vh;
    display: flex;
    font-size: 12px;
    align-items: center;
    font-family: 'Articulat.CF-V3-Heavy';
}

.stake-bottom {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    column-gap: 20px;
    row-gap: 10px;
    overflow: auto;
    height: 35vh;
}

.col {
    flex-direction: column;
}

.stake-bottom > h2 {
    font-family: 'Articulat.CF-V3-Heavy';
}

.stake-header {
    flex-basis: 70%;
    text-align: left;
    margin-left: 20px;
}

.stake-button {
    flex-basis: 20%;
}

.desc-viewer {
    flex-basis: 35%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 200px;
    height: 86vh;
    margin-top: 7vh;
}

.desc-top {
    text-align: center;
}

.small > a {
    cursor: pointer;
}

.loadingView > h1 {
    font-size: 60px;
    font-family: 'Articulat.CF-V3-Heavy';
}
.desc-top > h1 {
    font-size: 60px;
    font-family: 'Articulat.CF-V3-Heavy';
    padding-left: 5%;
    padding-right: 20%;
}
.desc-top > p {
    padding-left: 30%;
    padding-right: 20%;
    text-align: left;
}

.desc-bottom {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.desc-bottom > h2 {
    flex-basis: 100%;
}

.desc-bottom > button {
    border: 2px solid #f0f1f3;
    background-color: rgba(255, 255, 255, 0.15);
    width: 200px;
    height: 50px;
    color: #f0f1f3;
    font-size: 20px;
    transition: background-color 200ms ease-out;
    margin-left: 50px;
    border-radius: 3px;
}

.desc-bottom > button:hover {
    background-color: rgba(255, 255, 255, 0.23);
    transition: background-color 200ms ease-in;
}

.pink {
    color: #3e8af6;
}
/* Hide scrollbar for Chrome, Safari and Opera */

.stake-image-item {
    width: 100px;
    border-radius: 3px;
}

.small {
    font-size: 12px;
}

.image-holder:hover {
    opacity: 0.75;
    transition: opacity 200ms;
    transition-timing-function: ease-in;
    cursor: pointer;
}

.token {
    width: 60px;
}

.attribute {
    width: 80%;
    display: flex;
    text-align: left;
    flex-wrap: wrap;
    margin: 2px;
}

.attribute-left {
    flex-basis: 48%;
    padding: 0.5%;
}

.attribute-right {
    flex-basis: 48%;
    background-color: rgba(255, 255, 255, 0.2);
    padding: 0.5%;
}

.modal-image-item {
    height: 25vw;
    border-radius: 5px;
}

.modal-bg {
    background-color: rgba(0,0,0,0.4);
    position:fixed;
    top:0px;
    right:0px;
    bottom:0px;
    left:0px;
    z-index: 11;
}



@media only screen and (max-width: 1080px) {

    .modal-image-item {
        height: 16em;
        border-radius: 5px;
    }

    .modal-left {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 300px;
    }

    .modal-right {
        width: 100%;
    
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    .modal-right > p {
        text-align: left;
        width: 80%;
        margin-bottom: -10px;
    }

    .desc-viewer {
        flex-basis: 100%;
    }

    .stake-viewer {
        flex-basis: 100%;
        height: 100%;
    }

    .stake-box {
        flex-basis: 90%;
        margin-bottom: 20px;
    }
    
  }

  .stake-button {
    border-radius: 3px;
    border: none;
    padding: 5px;
    font-family: 'Articulat.CF-V3-Bold';
    cursor: pointer;
  
    opacity: 1;
    transition: opacity 500ms;
    transition-timing-function: ease-out;
    margin-right: 10PX;
  }
  
  .stake-button:hover {
    opacity: .5;
    transition: opacity 500ms;
    transition-timing-function: ease-in;
  }

